import { performGraphQLOperation } from "./GraphQLOperation";

export const fetchAllOfficeDays = async (userId, userToken) => {
  try {
    const query = "fetchOfficeDaysByUserIdQuery";
    const variables = { userId };
    const response = await performGraphQLOperation(query, variables, userToken);
    
    if (!response || !response.items || !Array.isArray(response.items)) {
      console.error('Unexpected response structure:', response);
      return [];
    }

    return response.items;
  } catch (error) {
    console.error("Error fetching office days by userID:", error);
    return [];
  }
};
