import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import { Box, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import Grid from "@mui/material/Grid";
import { fetchPreselectedAddDialogDataById } from "../utils/fetchPreselectedAddDialogData";

export default function AddDaysDialog({
  userId,
  userName,
  token,
  open,
  onClose,
  onAdd,
}) {
  const [dateFrom, setDateFrom] = useState(dayjs());
  const [dateTo, setDateTo] = useState(dayjs());
  const [location, setLocation] = useState("");
  const [parking, setParking] = useState(false);
  const [licensePlate, setLicensePlate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isDateRangeValid = (dateFrom, dateTo) => {
    // If both dates are selected, compare them
    if (dateFrom && dateTo) {
      return (
        dayjs(dateFrom).isSame(dayjs(dateTo)) ||
        dayjs(dateFrom).isBefore(dayjs(dateTo))
      );
    }
    // If only one date is selected, we can't determine the range validity yet
    return true;
  };

  const handleAdd = async () => {
    setIsLoading(true); // Start loading before the async operation
    try {
      const finalLicensePlate = parking ? licensePlate : null;
      await onAdd(
        userId,
        dateFrom,
        dateTo,
        location,
        parking,
        finalLicensePlate,
        token
      );
      onClose();
    } catch (error) {
      console.error("Failed to add days:", error);
    } finally {
      setIsLoading(false); // Stop loading after the async operation finishes
    }
  };

  const canAdd = () => {
    // Check if both dates are selected and valid, and if a location has been chosen
    return isDateRangeValid(dateFrom, dateTo) && location && dateFrom && dateTo;
  };

  // Fetch preselected data when the dialog opens and the userId is set
  useEffect(() => {
    const fetchData = async () => {
      if (open && userId) {
        setIsLoading(true); // Show loading state
        try {
          const { latestLocation, latestPlate } =
            await fetchPreselectedAddDialogDataById(userId, token);
          // Set the state of the dialog fields with the fetched data
          if (latestLocation) setLocation(latestLocation);
          if (latestPlate) setLicensePlate(latestPlate);
        } catch (error) {
          console.error("Error fetching preselected add dialog data:", error);
        } finally {
          setIsLoading(false); // Hide loading state
        }
      }
    };

    fetchData();
  }, [open, userId, token]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Office Days for: {userName}</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogContentText>
              Please select the office days, location, and parking preferences.
            </DialogContentText>
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Date From"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        helperText: "MM/DD/YYYY",
                      },
                    }}
                    value={dateFrom}
                    onChange={setDateFrom}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Date To"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        helperText: "MM/DD/YYYY",
                      },
                    }}
                    value={dateTo}
                    onChange={setDateTo}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>

            <FormControl fullWidth margin="normal">
              <InputLabel>Office Location</InputLabel>
              <Select
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                label="Office Location"
              >
                <MenuItem value="Athens">Athens Office</MenuItem>
                <MenuItem value="Heraklion">Heraklion Office</MenuItem>
                <MenuItem value="Atlanta">Atlanta Office</MenuItem>
              </Select>
            </FormControl>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                marginTop: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={parking}
                    onChange={(e) => setParking(e.target.checked)}
                  />
                }
                label="Parking"
              />
              <TextField
                label="License Plate"
                value={licensePlate}
                onChange={(e) => setLicensePlate(e.target.value)}
                disabled={!parking}
                style={{ flexGrow: 1 }}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={handleAdd}
          color="primary"
          disabled={isLoading || !canAdd()}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
