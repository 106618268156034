import { performGraphQLOperation } from "./GraphQLOperation";

export const fetchRoleByEmail = async (email, userToken) => {
  const query = 'fetchRolesByEmailQuery';
  const variables = { email };

  try {
    const response = await performGraphQLOperation(query, variables, userToken);
    const roles = response.data.listRoles.items;

    if (roles.length > 0 && roles[0].role === "admin") {
      return "admin";
    } else {
      // Return 'user' if there are no items or the role is not 'admin'
      return "user";
    }
  } catch (error) {
    console.error("Error fetching user role", error);
    return "user";
  }
};
