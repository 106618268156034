import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/en-gb";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CalendarCustomStyles.css";
import {
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { fetchCompanyCalendarData } from "../utils/fetchOfficeDaysForCompanyCalendar";
import { fetchLocations } from "../utils/fetchLocations";
import { useUserContext } from "../components/UserContext"; 

moment.locale("en-gb");
const localizer = momentLocalizer(moment);

function CompanyCalendar() {
  const [titleFilter, setTitleFilter] = useState([]);
  const [departmentFilter, setDepartmentFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [dateRange, setDateRange] = useState({
    DateFrom: dayjs().startOf("month").format("YYYY-MM-DD"),
    DateTo: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const [queryData, setQueryData] = useState([]);
  const [events, setEvents] = useState([]);
  const { user } = useUserContext();

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchCompanyCalendarData(dateRange.DateFrom, dateRange.DateTo, user.token);
        // Filter data based on the title, department, and location
        const filteredData = data.filter((item) => {
          const matchesDepartment = departmentFilter.length === 0 || departmentFilter.includes(item.user.department);
          const matchesLocation = locationFilter.length === 0 || locationFilter.includes(item.location);
          const matchesTitle = titleFilter.length === 0 || titleFilter.some(filter => item.user.name.toLowerCase().includes(filter.toLowerCase()));
          return matchesDepartment && matchesLocation && matchesTitle;
        });
        setQueryData(filteredData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setQueryData([]);
      }
    }

    if (user.token) { // Ensure there is a token before fetching data
      fetchData();
    }
  }, [dateRange, titleFilter, departmentFilter, locationFilter, user.token]); 

  useEffect(() => {
    const newEvents = queryData.map((officeDay) => {
      if (!officeDay.user) {
        return null; // Skip officeDay records with no user
      }
  
      const date = new Date(officeDay.date);
      date.setHours(10, 0, 0, 0);
      const endDate = new Date(officeDay.date);
      endDate.setHours(18, 0, 0, 0);
      return {
        id: officeDay.id,
        title: officeDay.user.name,
        start: date,
        end: endDate,
        department: officeDay.user.department,
        location: officeDay.location,
      };
    }).filter(event => event != null); // Filter out null values
    setEvents(newEvents);
  }, [queryData]);

  const onRangeChange = (range) => {
    let start, end;
  
    if (Array.isArray(range)) {
      // If range is an array, take the first and last dates from it
      start = dayjs(range[0]).format('YYYY-MM-DD');
      end = dayjs(range[range.length - 1]).format('YYYY-MM-DD');
    } else {
      // For 'month' view, the range will be an object with start and end dates
      start = dayjs(range.start).format('YYYY-MM-DD');
      end = dayjs(range.end).format('YYYY-MM-DD');
    }
  
    // Update the state with the new DateFrom and DateTo
    setDateRange({
      DateFrom: start,
      DateTo: end,
    });
  };

  const departments = [
    "R&D",
    "Customer Success",
    "Customer Support",
    "G&A",
    "Marketing",
    "Sales",
    "Talent Cards",
  ];

  //const locations = ["Athens", "Heracleion"];
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    // Define an async function inside the useEffect
    const loadLocations = async () => {
      try {
        const locationsFetched = await fetchLocations(user.token);
        setLocations(locationsFetched);
      } catch (error) {
        console.error('Error loading locations:', error);
      }
    };
  
    // Call the function
    loadLocations();
  }, []);

  const handleDepartmentChange = (event) => {
    const {
      target: { value },
    } = event;
    setDepartmentFilter(typeof value === "string" ? value.split(",") : value);
  };

  const handleLocationChange = (event) => {
    const {
      target: { value },
    } = event;
    setLocationFilter(typeof value === "string" ? value.split(",") : value);
  };

  const handleTitleFilterChange = (event) => {
    const names = event.target.value.split(',').map(name => name.trim()); // Split by comma and trim whitespace
    setTitleFilter(names); // Now `titleFilter` will be an array of names
  };

  const customDateFormats = {
    agendaDateFormat: "dddd, MMMM D YYYY", // Customize this format as you need
    //agendaTimeFormat: '',                    // Setting it to an empty string removes the time
  };

  return (
    <React.Fragment>
      <div style={{ minWidth: 1200 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          marginBottom: "1rem",
          marginTop: "2rem",
          marginLeft: "1rem",
          marginRight: "2rem",
        }}
      >
        <TextField
          label="Filter Epignosians"
          variant="outlined"
          value={titleFilter}
          onChange={handleTitleFilterChange}
          style={{ margin: "0 10px" }}
        />
        <FormControl style={{ margin: "0 3px" }}>
          <InputLabel
            id="department-select-label"
            style={{ maxWidth: "140px" }}
          >
            Departments
          </InputLabel>
          <Select
            labelId="department-select-label"
            multiple
            style={{ minWidth: "140px", maxWidth: "140px" }}
            value={departmentFilter}
            onChange={handleDepartmentChange}
            input={<OutlinedInput label="Departments" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {departments.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={departmentFilter.includes(name)} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ margin: "0 5px" }}>
          <InputLabel id="location-select-label" style={{ maxWidth: "150px" }}>
            Locations
          </InputLabel>
          <Select
            labelId="location-select-label"
            multiple
            style={{ minWidth: "120px", maxWidth: "150px" }}
            value={locationFilter}
            onChange={handleLocationChange}
            input={<OutlinedInput label="Locations" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {locations.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={locationFilter.includes(name)} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={["month"]}
        style={{ height: "750px", width: "100%", marginLeft: "1.75rem" }}
        culture="en-GB"
        formats={customDateFormats}
        popup={true}
        onRangeChange={onRangeChange}
      />
      </div>
    </React.Fragment>
  );
}

export default CompanyCalendar;
