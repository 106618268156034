import moment from 'moment';

class DateUtil {
  
  /**
   * Get all dates between two given dates.
   * @param {string} fromDate - Start date in "YYYY-MM-DD" format.
   * @param {string} toDate - End date in "YYYY-MM-DD" format.
   * @returns {string[]} Array of dates in "YYYY-MM-DD" format.
   */
  getDatesBetween(fromDate, toDate) {
    let dateArray = [];
    let currentDate = moment(fromDate, "YYYY-MM-DD");

    while (currentDate <= moment(toDate, "YYYY-MM-DD")) {
        dateArray.push(currentDate.format("YYYY-MM-DD"));
        currentDate = currentDate.add(1, 'days');
    }
    return dateArray;
  }

  /**
   * Filter out weekends from an array of dates.
   * @param {string[]} dateStringArray - Array of dates in "YYYY-MM-DD" format.
   * @returns {string[]} Array of dates without weekends.
   */
  excludeWeekends(dateStringArray) {
    const dateObjects = dateStringArray.map((dateString) => new Date(dateString));

    const removeWeekends = (dates) => {
      return dates.filter((date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6; // Not Sunday (0) and not Saturday (6)
      });
    };

    const filteredDates = removeWeekends(dateObjects);
    return filteredDates.map((date) => date.toISOString().split('T')[0]);
  }

  /**
   * Beautify an array of office days.
   * @param {Object[]} officeDaysList - Array of office day objects.
   * @returns {string} Beautified list of office days.
   */
  beautifyOfficeDays(officeDaysList) {
    if (!Array.isArray(officeDaysList) || officeDaysList.length === 0) {
        return 'No office days found.';
    }

    officeDaysList.sort((a, b) => new Date(a.date) - new Date(b.date));
    
    const beautifiedDates = officeDaysList.map(item => {
        const readableDate = moment(item.date).format('dddd, MMMM Do YYYY');
        return `• ${readableDate}`;
    });

    return beautifiedDates.join('\n');
  }

  /**
   * Get all dates for specific weekdays until the end of the year.
   * @param {number[]} weekdays - Array of day numbers (0-6, where 0 is Sunday and 6 is Saturday).
   * @returns {string[]} Array of dates in "YYYY-MM-DD" format for the given weekdays.
   */
  getAllDatesForWeekdaysUntilEndOfYear(weekdays) {
    let dates = [];
    let today = moment();
    let endOfYear = moment().endOf('year');

    while (today <= endOfYear) {
      if (weekdays.includes(today.day())) {
        dates.push(today.format("YYYY-MM-DD"));
      }
      today.add(1, 'days');
    }

    return dates;
  }
}

const dateUtilInstance = new DateUtil();
export default dateUtilInstance;
