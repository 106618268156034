import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { listOfficeDaysBetweenDates } from "../utils/listOfficeDaysBetweenDates";
import Papa from "papaparse";

const getCurrentTimestamp = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-based.
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  // Format: YYYY-MM-DD_HH-MM-SS
  return `${year}-${month}-${day}_h${hours}m${minutes}`;
};

const handleExportDays = async (
  dateFrom,
  dateTo,
  token
) => {
    // all office days
    const formattedDateFrom = dateFrom.format("YYYY-MM-DD");
    const formattedDateTo = dateTo.format("YYYY-MM-DD");
    let officeDaysForExport = await listOfficeDaysBetweenDates(formattedDateFrom, formattedDateTo, token); 

    // Sort the array in descending order by date
    officeDaysForExport.sort((a, b) => b.date.localeCompare(a.date));

    //create blob with csv data
    const csvData = officeDaysForExport.map((day) => {
      const hasParking = day.parking === true;

      return {
        Date: day.date,
        Name: day.user.name,
        Department: day.user.department,
        Office: day.location,
        Parking: hasParking ? "Yes" : "No",
        License_plate: hasParking 
          ? (!day.licensePlate || day.licensePlate.trim() === "" 
              ? "License plate not provided" 
              : day.licensePlate) 
          : "-",
      };
    });

    const csv = Papa.unparse({
      fields: ["Date", "Name", "Department", "Office", "Parking", "License_plate"],
      data: csvData,
    });

    const currTimestamp = getCurrentTimestamp();
    const fileName = "OAT_export_From_" + formattedDateFrom + "_To_" + formattedDateTo + "_At_" + currTimestamp + ".csv";

    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const csvURL = window.URL.createObjectURL(csvBlob);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", fileName);
    tempLink.click();
};

export default function ExportDaysDialog({
  open,
  onClose,
  token
}) {
  const [dateFrom, setDateFrom] = useState(dayjs());
  const [dateTo, setDateTo] = useState(dayjs());
  const [isLoading, setIsLoading] = useState(false);

  const isDateRangeValid = (dateFrom, dateTo) => {
    // If both dates are selected, compare them
    if (dateFrom && dateTo) {
      return (
        dayjs(dateFrom).isSame(dayjs(dateTo)) ||
        dayjs(dateFrom).isBefore(dayjs(dateTo))
      );
    }
    // If only one date is selected, we can't determine the range validity yet
    return true;
  };

  const handleExport = async () => {
    setIsLoading(true); // Start loading before the async operation
    try {
      await handleExportDays(
        dateFrom,
        dateTo,
        token
      );
      onClose();
    } catch (error) {
      console.error("Failed to export days:", error);
    } finally {
      setIsLoading(false); // Stop loading after the async operation finishes
    }
  };

  const canExport = () => {
    // Check if both dates are selected and valid, and if a location has been chosen
    return isDateRangeValid(dateFrom, dateTo) && dateFrom && dateTo;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Export Office Days</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="160px"
            width="480px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogContentText>
              Please select a date range.
            </DialogContentText>
            <br/>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Date From"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        helperText: "MM/DD/YYYY",
                      },
                    }}
                    value={dateFrom}
                    onChange={setDateFrom}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Date To"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        helperText: "MM/DD/YYYY",
                      },
                    }}
                    value={dateTo}
                    onChange={setDateTo}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={handleExport}
          color="primary"
          disabled={isLoading || !canExport()}
        >
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
}
