import { performGraphQLOperation } from "./GraphQLOperation";

export const fetchUserIdByEmail = async (email, userToken) => {
  const variables = { email };
  const query = "fetchUserByEmailQuery";

  try {
    const response = await performGraphQLOperation(query, variables, userToken);

    let users = [];
    if (response && response.items && Array.isArray(response.items)) {
      users = response.items;
    }
    if (users.length > 0) {
      return users[0].id;  // Return the ID of the first user found
    } else {
      console.log("No user found with email:", email);
      return null; // Return null indicating no user was found
    }
  } catch (error) {
    console.error("Error fetching user ID by email:", error);
    return null;  // Return null in case of error
  }
};
