import { performGraphQLOperation } from "./GraphQLOperation";

export const deleteOfficeDayOperation = async (dayId, token) => {
  const query = "deleteOfficeDayMutation";
  const variables = { id: dayId };

  try {
    const response = await performGraphQLOperation(query, variables, token); 
    const result = response.data.deleteOfficeDay;

    if (result && "id" in result) {
      console.log("OfficeDay:" + result.id + " got deleted.");
    } else {
      console.error("Error deleting OfficeDay: " + dayId);
    }
  } catch (error) {
    console.error("GraphQL Error:", error);
  }
};
