import axios from "axios";

export const performGraphQLOperation = async (query, variables, token) => {
  const lambdaEndpoint = process.env.REACT_APP_AUTH_LAMBDA_ENDPOINT;

  const requestBody = { query, variables };
  try {
    const response = await axios.post(lambdaEndpoint, requestBody, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, // Include the token here
        // Include any other necessary headers here, such as authorization tokens.
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error making GraphQL request:", error.message);
    throw new Error("Failed to make GraphQL request");
  }
};
