import { performGraphQLOperation } from "./GraphQLOperation";

export const fetchAllUsers = async (token) => {
  const query = "fetchAllUsersQuery";
  const variables = {};

  const response = await performGraphQLOperation(query, variables, token);
  
  let users = [];
  if (response && response.items && Array.isArray(response.items)) {
    users = response.items;
  } else {
    console.error("Unexpected response structure:", response);
  }
  
  return users;
};
