import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import OfficeDays from "./OfficeDays";
import Users from "./Users";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { fetchDetails } from "../utils/fetchOfficeUserData";
import { useUserContext } from "../components/UserContext";
import CompanyCalendar from "../components/CompanyCalendar";
import MuiDrawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import PeopleIcon from "@mui/icons-material/People";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AddIcon from "@mui/icons-material/Add";
import { Card, CardContent, CardActions } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { fetchUserByEmail } from "../utils/fetchUserByEmail";
import Button from "@mui/material/Button";
import AddDaysDialog from "../components/AddDaysDialog";
import ViewScheduleDialog from "../components/ViewScheduleDialog";
import dateUtil from "../utils/dateUtil";
import { createOfficeDaysOperation } from "../utils/createOfficeDays";
import AddMessagePopup from "../components/AddMessageDialog";
import RepeatIcon from "@mui/icons-material/Repeat";
import RecurringDaysDialog from "../components/RecurringDaysDialog";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.epignosishq.com/">
        Epignosis
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#226fba', 
    },
    secondary: {
      main: '#e46026', 
    },
    success: {
      main: '#009975', 
    },
    error: { 
      main: '#dc3545', 
    },
    info: {
      main: '#17a2b8', 
    },
    // Add other custom colors or configurations as needed
  },
  // Customize other aspects of the theme (typography, components, etc.) as needed
});

export default function Dashboard() {
  const [open, setOpen] = React.useState(false);
  const [currentView, setCurrentView] = useState("home");
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [userDetails, setUserDetails] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [isLoading, setIsLoading] = useState(false);
  const { signOut, user, currentUserId } = useUserContext();

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const usersFetched = await fetchDetails(selectedDate, user.token);
        if (!usersFetched.length) {
          setUserDetails();
        } else {
          setUserDetails(usersFetched);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data: ", error);
      }
    })();
  }, [selectedDate]);

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [addMessageDialogOpen, setAddMessageDialogOpen] = useState(false);
  const [addMessage, setAddMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [recurringDialogOpen, setRecurringDialogOpen] = React.useState(false);
  const [operationResult, setOperationResult] = useState({
    newDates: [],
    duplicates: [],
    capacityReached: [],
    deletedDays: [],
  });

  const handleOpenDialog = (user) => {
    setSelectedUser(user);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedUser(null);
  };

  const handleOpenScheduleDialog = (user) => {
    setSelectedUser(user);
    setScheduleDialogOpen(true);
  };


  const handleCloseScheduleDialog = () => {
    setScheduleDialogOpen(false);
    setSelectedUser(null);
  };

  const handleCloseMessageDialog = () => {
    setAddMessageDialogOpen(false);
    setAddMessage("");
    setOperationResult({ newDates: [], duplicates: [], capacityReached: [],  deletedDays: []  }); // Resetting the operation result
  };

  const handleOpenRecurringDialog = () => {
    setRecurringDialogOpen(true);
  };
  const handleCloseRecurringDialog = () => {
    setRecurringDialogOpen(false);
  };

  const showAddMessage = (newDates, duplicates, capacityReached, deletedDays) => {
    setOperationResult({ newDates, duplicates, capacityReached, deletedDays });
  };

  const handleAddDays = async (
    userId,
    dateFrom,
    dateTo,
    location,
    parking,
    licensePlate,
    token
  ) => {
    console.log(`Adding days for user ${userId}`);

    const formattedDateFrom = dateFrom.format("YYYY-MM-DD");
    const formattedDateTo = dateTo.format("YYYY-MM-DD");
    const dates = dateUtil.excludeWeekends(
      dateUtil.getDatesBetween(formattedDateFrom, formattedDateTo)
    );
    console.log(dates);
    const { duplicates, newDates, capacityReached } = await createOfficeDaysOperation(
        userId,
        dates,
        location,
        parking,
        licensePlate,
        token
      );
    showAddMessage(newDates, duplicates, capacityReached, []);
  };

  useEffect(() => {
    let messageText = "";

    if (operationResult.newDates.length > 0) {
      const totalDaysAdded = operationResult.newDates.length;
      messageText += `Operation completed! Total days added: ${totalDaysAdded} \n\n`;
    }
    
    if (operationResult.duplicates.length > 0) {
      const duplicatesList = operationResult.duplicates
        .map((item) => item.date)
        .join(", ");
      messageText += `Office days NOT added because entry already exists: \n${duplicatesList}\n`;
      messageText +=
        "If you want to add those dates, please remove previous entries first. \n\n";
    }
    if (operationResult.capacityReached.length > 0) {
      const capacityReachedList = operationResult.capacityReached
      .map((item) => item.date)
      .join(", ");
      messageText += `Office days NOT added because the Office is at full capacity: \n ${capacityReachedList}.\n`;
    }
    if (operationResult.deletedDays.length > 0) {
      const deletedDaysList = operationResult.deletedDays.length;
      messageText += `Operation completed! Total days removed: ${deletedDaysList} \n`;
    }
    if (messageText) {
      setAddMessage(messageText);
      setAddMessageDialogOpen(true);
    }
  }, [operationResult]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Office Attendance Tool
            </Typography>
            <IconButton color="inherit" onClick={handleLogout}>
              <ExitToAppIcon />
              <Typography variant="body1" color="inherit" noWrap>
                Logout
              </Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <ListItemButton onClick={() => setCurrentView("home")}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
            <ListItemButton onClick={() => setCurrentView("monthly")}>
              <ListItemIcon>
                <CalendarMonthIcon />
              </ListItemIcon>
              <ListItemText primary="Monthly" />
            </ListItemButton>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundImage:
              "url(./../public/offices.jpg), linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3))",
            backgroundBlendMode: "overlay",
            backgroundSize: "cover",
            backgroundPosition: "center",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={5}>
              {currentView === "monthly" && <CompanyCalendar />}
              {currentView === "home" && (
                  <>
                    {/* My Office Days section */}
                    {currentUserId && (
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={12}>
                        <Card sx={{ mb: 4 }}>
                          <CardContent>
                            <Typography
                              variant="h5"
                              component="h2"
                              textAlign="center"
                            >
                              My Office Days
                            </Typography>
                          </CardContent>
                          <CardActions
                            sx={{ justifyContent: "center", flexWrap: "wrap" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<AddIcon />}
                              onClick={() => setDialogOpen(true)}
                            >
                              Add Office Day
                            </Button>
                            <Button
                              variant="contained"
                              color="primary" 
                              startIcon={<CalendarTodayIcon />}
                              onClick={() => setScheduleDialogOpen(true)}
                            >
                              View Schedule
                            </Button>
                            <Button
                              variant="contained"
                              color="primary" 
                              startIcon={<RepeatIcon />} 
                              onClick={() => setRecurringDialogOpen(true)}
                            >
                              Set Recurring Days
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    </Grid>
                    )}
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={12} sm={4} md={3}>
                        <OfficeDays onDateChange={handleDateChange} />
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Paper sx={{ p: 2, minHeight: "727px" }}>
                          {isLoading ? (
                            <CircularProgress sx={{ alignSelf: "center" }} />
                          ) : userDetails && userDetails.length > 0 ? (
                            <Users users={userDetails} />
                          ) : (
                            <Typography
                              variant="h6"
                              textAlign="center"
                              sx={{ mt: 4 }}
                            >
                              No one is in office today.
                            </Typography>
                          )}
                        </Paper>
                      </Grid>
                    </Grid>
                  </>
                )}

                {/* Dialogs for Adding and Viewing Office Days */}
                {dialogOpen && (
                  <AddDaysDialog
                    userId={currentUserId}
                    token={user.token}
                    userName={user.name}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    onAdd={handleAddDays}
                  />
                )}
                {scheduleDialogOpen && (
                  <ViewScheduleDialog
                    userId={currentUserId}
                    token={user.token}
                    userName={user.name}
                    open={scheduleDialogOpen}
                    onClose={() => setScheduleDialogOpen(false)}
                  />
                )}
                {addMessage && (
                  <AddMessagePopup
                    open={addMessageDialogOpen}
                    token={user.token}
                    onClose={handleCloseMessageDialog}
                    addMessage={addMessage}
                  />
                )}
                {recurringDialogOpen && (
                  <RecurringDaysDialog
                    open={recurringDialogOpen}
                    onClose={handleCloseRecurringDialog}
                    userId={currentUserId}
                    token={user.token}
                    showAddMessage={showAddMessage}  
                    setOperationResult={setOperationResult}                 />
                )}
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}