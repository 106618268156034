import { performGraphQLOperation } from "./GraphQLOperation";
import dayjs from "dayjs";

const fetchOfficeDays = async (query, selectedDate, userToken) => {
  const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");

  const variables = {
    date: formattedDate,
  };

  try {
    const result = await performGraphQLOperation(query, variables, userToken);

    if (result.errors && result.errors.length > 0) {
      console.error("GraphQL Errors:", result.errors);
      throw new Error("Failed to execute GraphQL query.");
    }

    // Adjusted to handle the correct structure
    if (result.items) {
      return result.items; // Return the fetched items
    } else if (result.data && result.data.items) {
      return result.data.items; // Return the fetched items
    } else if (result.data && result.data.listOfficeDays && result.data.listOfficeDays.items) {
      return result.data.listOfficeDays.items; // Return the fetched items
    } else {
      console.error("Invalid response structure:", result);
      return []; // Return an empty array if the structure is not as expected
    }
  } catch (error) {
    console.error("Error fetching office days:", error);
    return []; // Return an empty array or handle the error as needed
  }
};

export const fetchOfficeDaysForDate = (selectedDate, userToken) =>
  fetchOfficeDays("fetchOfficeDaysByDateQuery", selectedDate, userToken);

export const adminFetchOfficeDaysForDate = (selectedDate, userToken) =>
  fetchOfficeDays("adminFetchOfficeDaysByDateQuery", selectedDate, userToken);

export const fetchDetails = async (selectedDate, userToken) => {
  const officeDays = await fetchOfficeDaysForDate(selectedDate, userToken);
  return officeDays.map((officeDay) => ({
    id: officeDay.user.id,
    imageUrl: officeDay.user.imageUrl,
    name: officeDay.user.name,
    department: officeDay.user.department,
    location: officeDay.location,
    parking: officeDay.parking,
  }));
};

export const adminFetchDetails = async (selectedDate, userToken) => {
  const officeDays = await adminFetchOfficeDaysForDate(selectedDate, userToken);
  return officeDays.map((officeDay) => ({
    id: officeDay.user.id,
    imageUrl: officeDay.user.imageUrl,
    name: officeDay.user.name,
    department: officeDay.user.department,
    location: officeDay.location,
    parking: officeDay.parking,
    licensePlate: officeDay.licensePlate,
  }));
};
