import { performGraphQLOperation } from "./GraphQLOperation";

export const fetchCompanyCalendarData = async (dateFrom, dateTo, userToken) => {
  const variables = { dateFrom, dateTo };

  try {
    const response = await performGraphQLOperation(
      "fetchOfficeDaysAndUsersBetweenDates",
      variables,
      userToken
    );

    if (!response || !response.items) {
      console.error('Unexpected response structure:', response);
      throw new Error('Failed to execute GraphQL query.');
    }

    return response.items;
  } catch (error) {
    console.error("Error fetching office days:", error);
    throw error; // Rethrow the error to handle it outside
  }
};
