import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/en-gb";
import Button from "@mui/material/Button";
import { DeleteMessagePopup } from "./AddMessageDialog";
import { deleteOfficeDayOperation } from "../utils/deleteOfficeDay";
import { useUserContext } from '../components/UserContext'; 

moment.locale("en-gb");
const localizer = momentLocalizer(moment);

function withDeleteHandler(Component, onDelete) {
  return function (props) {
    return <Component {...props} onDelete={onDelete} />;
  };
}

function CustomXEvent({ event, onDelete }) {
  return (
    <div style={{ position: "relative" }}>
      {event.title}
      <span
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
          cursor: "pointer",
          color: "red",
          backgroundColor: "white",
          padding: "1px 3px",
          borderRadius: "10%",
        }}
        onClick={() => onDelete(event.id)}
      >
        X
      </span>
    </div>
  );
}

function OfficeDaysCalendar({ officeDays, onDayDeleted }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [officeDayToDelete, setOfficeDayToDelete] = useState(null);

  const customDateFormats = {
    agendaDateFormat: "dddd, MMMM D YYYY", // Customize this format as you need
    //agendaTimeFormat: '',                    // Setting it to an empty string removes the time
  };

  const customMessages = {
    agenda: 'List',
  };
  const { user } = useUserContext(); 

  const handleDeleteClick = (dayId) => {
    setOfficeDayToDelete(dayId);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    console.log(JSON.stringify(officeDayToDelete));
    await deleteOfficeDayOperation(officeDayToDelete, user.token); 
    setOpenDialog(false);
    onDayDeleted && onDayDeleted(officeDayToDelete);
  };

  const EnhancedCustomXEvent = withDeleteHandler(
    CustomXEvent,
    handleDeleteClick
  );

  const events = officeDays.map((day) => {
    const date = new Date(day.date);
    date.setHours(10, 0, 0, 0);
    const endDate = new Date(day.date);
    endDate.setHours(18, 0, 0, 0);
    return {
      id: day.id,
      title: `${day.location} ${
        day.parking
          ? ` 🚗  ${
              day.licensePlate ? day.licensePlate : ""
            }`
          : ""
      }`,
      start: date,
      end: date,
    };
  });

  return (
    <div style={{}}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={["month", "agenda"]}
        style={{ height: 550 }}
        culture="en-GB"
        formats={customDateFormats}
        messages={customMessages}
        components={{
          event: EnhancedCustomXEvent,
        }}
      />
      <DeleteMessagePopup
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        message={`Are you sure you want to delete this office day?`}
      >
        <Button color="primary" onClick={handleConfirmDelete}>
          Delete
        </Button>
        <Button color="secondary" onClick={() => setOpenDialog(false)}>
          Cancel
        </Button>
      </DeleteMessagePopup>
    </div>
  );
}

export default OfficeDaysCalendar;
