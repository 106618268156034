import { performGraphQLOperation } from "./GraphQLOperation";

export const fetchLocations = async (userToken) => {
  try {
    const response = await performGraphQLOperation("listLocations", {}, userToken);
    const locationList = response.data.listLocations.items;
    return locationList.map((location) => location.name);
  } catch (error) {
    console.error("Error fetching locations:", error);
    return [];
  }
};
