import { performGraphQLOperation } from "./GraphQLOperation";

export const fetchPreselectedAddDialogDataById = async (userId, userToken) => {
  const query = "fetchOfficeDaysByUserIdQuery";

  try {
    const response = await performGraphQLOperation(query, { userId }, userToken);

    if (!response || !response.items) {
      throw new Error('Unexpected response structure from GraphQL');
    }

    const officeDays = response.items;

    // Proceed to process the fetched office days for the latest location and license plate
    if (officeDays.length === 0) {
      return {
        latestLocation: null,
        latestPlate: null,
      };
    } else {
      // Sort by date descending
      const sortedOfficeDays = officeDays.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      const latestOfficeDay = sortedOfficeDays[0];
      const officeDaysWithLicense = sortedOfficeDays.filter(
        (day) => day.licensePlate
      );
      const latestOfficeDayWithLicense = officeDaysWithLicense.length > 0 ? officeDaysWithLicense[0] : null;

      return {
        latestLocation: latestOfficeDay.location,
        latestPlate: latestOfficeDayWithLicense ? latestOfficeDayWithLicense.licensePlate : null,
      };
    }
  } catch (error) {
    console.error("Error fetching office days by user ID:", error);
    return {
      latestLocation: null,
      latestPlate: null,
    };
  }
};
