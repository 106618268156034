import { performGraphQLOperation } from "./GraphQLOperation";

const officeCapacities = {
  Athens: 90,
  Heraklion: 8,
  Atlanta: 6,
};

export const createOfficeDaysOperation = async (
  userId,
  dates,
  location,
  parking,
  licensePlate,
  userToken,
  logFunction = () => {} 
) => {

  let duplicates = [];
  let newDates = [];
  let capacityReached = [];

  for (const date of dates) {
    const dateExists = await checkIfDateExists(userId, date, userToken);
    if (!dateExists) {
      const isCapacityReached = await checkOfficeCapacityForDate(
        location,
        date,
        userToken
      );
      if (!isCapacityReached) {
        logFunction(`Capacity not reached for ${date}, creating office day`);

        try {
          const variables = {
            userID: userId,
            date: date,
            location: location,
            parking: parking,
            licensePlate: licensePlate,
          };
          const response = await performGraphQLOperation(
            "createOfficeDayMutation",
            variables,
            userToken
          );
          const result = response.data;

          if (result.createOfficeDay && result.createOfficeDay.id) {
            logFunction(`Office day for ${date} added`);

            newDates.push({ date: date });
          }
        } catch (error) {
          logFunction(`Error creating office day for ${date}: ${error.message}`);
        }
      } else {
        logFunction(`Capacity reached for ${date}, not creating office day`);
        capacityReached.push({ date: date, location: location });
      }
    } else {
      logFunction(`Duplicate entry found for ${date}`);
      duplicates.push({ date: date });
    }
  }

  return {
    duplicates: duplicates,
    newDates: newDates,
    capacityReached: capacityReached,
  };
};

export const checkIfDateExists = async (userId, date, userToken) => {
  const query = "checkIfDateExistsQuery";
  try {
    const variables = { userId, date };
    const result = await performGraphQLOperation(query, variables, userToken);
    if (
      result &&
      result.items &&
      Array.isArray(result.items) &&
      result.items.length > 0
    ) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error checking if date exists:", error);
    return false;
  }
};

export const checkOfficeCapacityForDate = async (location, date, userToken) => {
  console.log(`Checking capacity for ${location} on ${date}`);

  const variables = { date: date };

  try {
    const response = await performGraphQLOperation(
      "fetchOfficeDaysByDateQuery",
      variables,
      userToken  
    );
    
    if (
      response &&
      response.items &&
      Array.isArray(response.items)
    ) {
      const currentBookings = response.items.filter(
        (item) => item.location === location
      ).length;
      const capacityReached = currentBookings >= officeCapacities[location];
      console.log(
        `Capacity ${
          capacityReached ? "reached" : "not reached"
        } for ${location} on ${date}`
      );
      return capacityReached;
    } else {
      console.log(
        `Unable to determine capacity for ${location} on ${date}, defaulting to false.`
      );
      return false; // Return false if unable to determine capacity due to lack of data.
    }

  } catch (error) {
    console.error("Error when checking capacity:", error);
    return null; // Indicate an error occurred while trying to check capacity.
  }
};
