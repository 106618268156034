// MessageDialog.js
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export default function AddMessagePopup({ open, onClose, addMessage }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Notification</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ whiteSpace: 'pre-line' }}>
                    {addMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Got it!
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export function DeleteMessagePopup({ open, onClose, message, children }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Delete Office Day</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ whiteSpace: 'pre-line' }}>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {children}
            </DialogActions>
        </Dialog>
    );
}
