import React, { useState, useEffect } from "react";
import { performGraphQLOperation } from "../utils/GraphQLOperation";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Box,
} from "@mui/material";
import dateUtil from "../utils/dateUtil";
import { createOfficeDaysOperation } from "../utils/createOfficeDays";
import { fetchPreselectedAddDialogDataById } from "../utils/fetchPreselectedAddDialogData";

const weekdays = [
  { label: "Monday", value: 1 }, // Adjust value to match moment's day of the week (1-5 for Mon-Fri)
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
];

const RecurringDaysDialog = ({ open, onClose, userId, token, showAddMessage, setOperationResult }) => {
  const [mode, setMode] = useState("add");
  const [selectedDays, setSelectedDays] = useState([]);
  const [location, setLocation] = useState("");
  const [parking, setParking] = useState(false);
  const [licensePlate, setLicensePlate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [logMessages, setLogMessages] = useState([]);

  const handleDayChange = (dayValue) => {
    const newSelectedDays = selectedDays.includes(dayValue)
      ? selectedDays.filter((day) => day !== dayValue)
      : [...selectedDays, dayValue];
    setSelectedDays(newSelectedDays);
  };

  const addLogMessage = (message) => {
    setLogMessages((prevLogs) => {
      const newLogs = [...prevLogs, message];
      return newLogs.slice(-3); // Keep only the last 3 messages
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    // Calculate the recurring dates once, outside of the add/remove conditional logic
    setLogMessages([]); // Clear previous log messages
    const recurringDates = dateUtil.getAllDatesForWeekdaysUntilEndOfYear(selectedDays);

    try {
      // console.log("Selected Days:", selectedDays);
      let messageText = ""; // Initialize a variable to hold your message

      if (mode === "add") {
        addLogMessage("Submitting Add with the following details:");
        addLogMessage(`Dates: ${recurringDates.join(", ")}`);
        addLogMessage(`Location: ${location}`);
        addLogMessage(`Parking: ${parking}`);
        addLogMessage(`License Plate: ${licensePlate}`);
        
        const { duplicates, newDates, capacityReached } = await createOfficeDaysOperation(
          userId,
          recurringDates,
          location,
          parking,
          licensePlate,
          token,
          addLogMessage
        );
        showAddMessage(newDates, duplicates, capacityReached, []);
        addLogMessage(`New Dates: ${newDates.map(date => date.date).join(", ")}`);
      } else {
        //   if (mode === "remove")

        let deletedDays = [];

        for (const date of recurringDates) {
          addLogMessage(`Checking entry for date: ${date}`);
          // First, check if the day exists
          try {
            const checkResult = await performGraphQLOperation("checkIfDateExistsQuery", { userId, date }, token);
            
            const items = checkResult.items;
            if (items && items.length > 0) {
              addLogMessage(`Entry found for date: ${date}. Deleting...`);
              const dayId = items[0].id;
              const deleteQuery = "deleteOfficeDayMutation";
              try {
                await performGraphQLOperation(deleteQuery, { id: dayId }, token);
                deletedDays.push({ date: date });
                addLogMessage(`Successfully deleted office day on ${date}`);
              } catch (error) {
                addLogMessage(`Failed to delete office day with ID ${dayId}: ${error.message}`);
              }
            } else {
              addLogMessage(`No entry found for date: ${date}.`);
            }
          } catch (error) {
            addLogMessage(`Error checking if date exists for ${date}: ${error.message}`);
          }
        }
        setOperationResult({ deletedDays: deletedDays });
        showAddMessage([], [], [], deletedDays);
      }
    } catch (error) {
      addLogMessage(`Error processing recurring days: ${error.message}`);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const canSubmit = () => {
    if (mode === "add") {
      const hasLocation = location !== "";
      const hasLicensePlate = !parking || (parking && licensePlate.trim() !== "");
      return selectedDays.length > 0 && hasLocation && hasLicensePlate;
    } else {
      return selectedDays.length > 0;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (open && userId) {
        setIsLoading(true); // Show loading state
        try {
          const { latestLocation, latestPlate } = await fetchPreselectedAddDialogDataById(userId, token);
          if (latestLocation) setLocation(latestLocation);
          if (latestPlate) setLicensePlate(latestPlate);
        } catch (error) {
          console.error("Error fetching preselected add dialog data:", error);
        } finally {
          setIsLoading(false); // Hide loading state
        }
      }
    };

    fetchData();
  }, [open, userId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Set Recurring Days</DialogTitle>
      <DialogContent>
        <ToggleButtonGroup
          color="primary"
          value={mode}
          exclusive
          onChange={(event, newMode) => setMode(newMode)}
          fullWidth
        >
          <ToggleButton value="add">Add</ToggleButton>
          <ToggleButton value="remove">Remove</ToggleButton>
        </ToggleButtonGroup>
        <FormGroup>
          {weekdays.map((day) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDays.includes(day.value)}
                  onChange={() => handleDayChange(day.value)}
                />
              }
              label={day.label}
              key={day.value}
            />
          ))}
        </FormGroup>
        {mode === "add" && selectedDays.length > 0 && (
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel>Office Location</InputLabel>
              <Select
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                label="Office Location"
              >
                <MenuItem value="Athens">Athens Office</MenuItem>
                <MenuItem value="Heraklion">Heraklion Office</MenuItem>
                <MenuItem value="Atlanta">Atlanta Office</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={parking}
                  onChange={(e) => setParking(e.target.checked)}
                />
              }
              label="Need Parking?"
            />
            {parking && (
              <TextField
                fullWidth
                margin="normal"
                label="License Plate"
                value={licensePlate}
                onChange={(e) => setLicensePlate(e.target.value)}
              />
            )}
          </>
        )}
        {isLoading && (
          <Box>
            <CircularProgress style={{ display: "block", margin: "10px auto" }} />
            <Box mt={2} p={2} bgcolor="#f0f0f0" borderRadius="4px">
              {logMessages.map((msg, index) => (
                <div key={index}>{msg}</div>
              ))}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={!canSubmit() || isLoading}>
          {mode === "add" ? "Add" : "Remove"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecurringDaysDialog;
