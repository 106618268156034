import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { fetchAllOfficeDays } from '../utils/fetchOfficeDaysByUserId';
import './Calendar.css';
import OfficeDaysCalendar from './OfficeDaysCalendar';

export default function ViewScheduleDialog({ userId, userName, token, open, onClose }) {
  const [officeDays, setOfficeDays] = useState([]);

  useEffect(() => {
    const fetchOfficeDaysData = async () => {
      try {
        const days = await fetchAllOfficeDays(userId, token);
        setOfficeDays(days);
      } catch (error) {
        console.error('Failed to fetch office days:', error);
      }
    };

    if (open) {
      fetchOfficeDaysData();
    }
  }, [open, userId, token]);

  const handleDayDeleted = () => {
    const refetchOfficeDaysData = async () => {
      try {
        const days = await fetchAllOfficeDays(userId, token);
        setOfficeDays(days);
      } catch (error) {
        console.error('Failed to re-fetch office days after deletion:', error);
      }
    };
    refetchOfficeDaysData();
  };

  return (
    <Dialog open={open} 
            onClose={onClose}
            fullWidth={true}
            maxWidth="lg"
            PaperProps={{
                style: {
                    height: '90vh',
                    padding: '20px',
                },
            }}>
      <DialogTitle>{userName} - Office Days</DialogTitle>
      <DialogContent>
            {/* You can render the calendar here and highlight the days from officeDays */}
        <OfficeDaysCalendar officeDays={officeDays} onDayDeleted={handleDayDeleted} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
