import { performGraphQLOperation } from "./GraphQLOperation";

export const listOfficeDaysBetweenDates = async (dateFrom, dateTo, userToken) => {
  const query = "listOfficeDaysBetweenDatesQuery";
  const variables = { dateFrom, dateTo };

  try {
    const response = await performGraphQLOperation(query, variables, userToken);

    if (!response || !response.items) {
      throw new Error('Unexpected response structure from GraphQL');
    }

    return response.items;
  } catch (error) {
    console.error("Error listing office days between dates:", error);
    return [];
  }
};
