import * as React from "react";
import Title from "./Title";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

export default function Users({ users }) {
  const columns = [
    {
      name: "imageUrl",
      header: "Image",
      defaultWidth: 100,
      render: ({ value }) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img
            src={value}
            alt="User"
            style={{ width: "50px", height: "50px" }}
          />
        </div>
      ),
    },
    { name: "name", header: "Name", defaultFlex: 1 },
    { name: "department", header: "Department", defaultFlex: 1 },
    { name: "location", header: "Office", defaultFlex: 1 },
  ];

  const filterValue = [
    { name: "name", operator: "contains", type: "string" },
    { name: "department", operator: "contains", type: "string" },
    { name: "location", operator: "contains", type: "string" },
  ];

  return (
    <React.Fragment>
      <Title>In Office</Title>
      <div style={{ height: 720, width: "100%" }}>
        <ReactDataGrid
          idProperty="id" // assuming each user has a unique 'id' field
          dataSource={users}
          columns={columns}
          style={{ minHeight: "720px", width: "100%" }}
          rowHeight={55}
          sortable={true}
          pagination={true}
          enableFiltering={true}
          defaultFilterValue={filterValue}
          // other props as needed
        />
      </div>
    </React.Fragment>
  );
}
