import * as React from "react";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import dayjs from "dayjs";

export default function OfficeDays({ onDateChange }) {
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handleDateChange = (newDate) => {
    if (dayjs.isDayjs(newDate)) {
      // ensure it's a Day.js object
      setSelectedDate(newDate);
      onDateChange(newDate);
    }
  };

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          value={selectedDate}
          onChange={handleDateChange}
          sx={{
            height: "423px",
          }}
        />
      </LocalizationProvider>
    </React.Fragment>
  );
}
