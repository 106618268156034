import React, { useState, useEffect } from "react";
import {
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { fetchCompanyCalendarData } from "../utils/fetchOfficeDaysForCompanyCalendar";

function Insights() {

  return (
    <React.Fragment>
        <h1>Insights</h1>
    </React.Fragment>
  );
}

export default Insights;
