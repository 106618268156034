import React, { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { Routes, Route } from "react-router-dom";
import { useUserContext } from "./components/UserContext";
import withRoleProtection from "./components/HOCRole";
import AdminDashboard from "./dashboard/AdminDashboard";
import UserDashboard from "./dashboard/Dashboard";
import Layout from "./components/Layout";

const ProtectedUserDashboard = withRoleProtection(UserDashboard, ["user"]);
const ProtectedAdminDashboard = withRoleProtection(AdminDashboard, ["admin"]);

function App() {
  const { setUser, role, isSignedIn, setIsSignedIn, currentUserId } = useUserContext();
  
  function handleCallbackResponse(response) {
    var userObject = jwtDecode(response.credential);
    setUser({ ...userObject, token: response.credential });
    setIsSignedIn(true);
  }

  useEffect(() => {
    // Helper function to load Google Sign-in
    function loadGoogleSignIn() {
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id:
            process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleCallbackResponse,
        });

        window.google.accounts.id.renderButton(
          document.getElementById("signInDiv"),
          { theme: "outline", size: "large" }
        );

        window.google.accounts.id.prompt(); // Display the One Tap prompt
      } else {
        // Retry loading google sign-in after a delay if it's undefined
        setTimeout(loadGoogleSignIn, 100);
      }
    }

    // Attempt to load Google Sign-in immediately
    loadGoogleSignIn();
  }, []);

  useEffect(() => {
    const signInDiv = document.getElementById("signInDiv");
    const welcomeDiv = document.getElementById("welcomeDiv");
    if (welcomeDiv) {
      welcomeDiv.style.display = isSignedIn ? "none" : "flex";
    }
    if (signInDiv) {
      signInDiv.style.display = isSignedIn ? "none" : "flex";
    }
  }, [isSignedIn]);

  const dashboardRouteElement =
    role === "admin" ? <ProtectedAdminDashboard /> : <ProtectedUserDashboard />;

  return (
    <div>
      <div
        id="welcomeDiv"
        style={{
          display: isSignedIn ? "none" : "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Epignosis Office Attendance Tool</h2>
      </div>
      <div
        id="signInDiv"
        style={{
          display: isSignedIn ? "none" : "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={dashboardRouteElement} />
          {/* Define other routes as needed */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
