import * as React from "react";
import Title from "./Title";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { Button } from "@mui/material";
import Papa from "papaparse";
import SaveIcon from "@mui/icons-material/Save";

export default function Users({ users }) {
  // Preprocess the data to replace null values in licensePlate with empty strings
  const preprocessedUsers = users.map((user) => ({
    ...user,
    licensePlate: user.licensePlate || "",
  }));

  const columns = [
    {
      name: "imageUrl",
      header: "Image",
      defaultWidth: 90,
      render: ({ value }) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img
            src={value}
            alt="User"
            style={{ width: "50px", height: "50px" }}
          />
        </div>
      ),
    },
    { name: "name", header: "Name", defaultFlex: 1 },
    { name: "department", header: "Department", defaultFlex: 0.8 },
    { name: "location", header: "Office", defaultFlex: 0.7 },
    { name: "parking", header: "Parking", defaultFlex: 0.7, 
    render: ({ data }) => {
      if (data.parking) {
        return "Yes";
      }else{
        return "No"
      }
      }
    },

    {
      name: "licensePlate",
      header: "License plate",
      defaultFlex: 0.8,
      render: ({ data }) => {
        if (data.parking) {
          if (!data.licensePlate || data.licensePlate.trim() === "") {
            return "No plate provided";
          }
          return data.licensePlate;
        }
        return "-";
      },
    },
  ];

  const filterValue = [
    { name: "name", operator: "contains", type: "string" },
    { name: "department", operator: "contains", type: "string" },
    { name: "location", operator: "contains", type: "string" },
    { name: "licensePlate", operator: "contains", type: "string" },
  ];

  const exportToCSV = () => {
    const csvData = users.map((user) => ({
      Name: user.name,
      Department: user.department,
      Office: user.location,
      Parking: user.parking? "Yes" : "No",
      License_plate:
        user.parking? !user.licensePlate || user.licensePlate.trim() === ""
          ? "No plate provided"
          : user.licensePlate
        : "-",
    }));

    const csv = Papa.unparse({
      fields: ["Name", "Department", "Office", "Parking", "License_plate"],
      data: csvData,
    });

    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const csvURL = window.URL.createObjectURL(csvBlob);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "daily_attendance_export.csv");
    tempLink.click();
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "8px",
        }}
      >
        <Title>In Office</Title>
        <Button
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={exportToCSV}
        >
          Export to CSV
        </Button>
      </div>
      <div style={{ height: 720, width: "100%" }}>
        <ReactDataGrid
          idProperty="id" // assuming each user has a unique 'id' field
          dataSource={preprocessedUsers}
          columns={columns}
          style={{ minHeight: "720px", width: "100%" }}
          rowHeight={55}
          sortable={true}
          pagination={true}
          enableFiltering={true}
          defaultFilterValue={filterValue}
          // other props as needed
        />
      </div>
    </React.Fragment>
  );
}
