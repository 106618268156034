import React from 'react';
import { useUserContext } from './UserContext';
import { Navigate } from 'react-router-dom';

const withRoleProtection = (WrappedComponent, allowedRoles) => {
  return (props) => {
    const { role } = useUserContext();
    if (!allowedRoles.includes(role)) {
      return <Navigate to="/" replace />;
    }
    return <WrappedComponent {...props} />;
  };
};

export default withRoleProtection;
