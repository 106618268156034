import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchRoleByEmail } from '../utils/checkUserRoles';
import { fetchUserIdByEmail } from '../utils/fetchUserByEmail';

const UserContext = createContext(null);

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [role, setRole] = useState('guest');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null); 

  useEffect(() => {
    // Make sure user is not null and has an 'email' property before attempting to fetch the role
    if (user?.email && user?.token) {  // Check that user is not null and has email and token
      fetchRoleByEmail(user.email, user.token)
        .then((userRole) => {
          setRole(userRole);
        })
        .catch((error) => {
          console.error('Error fetching user role', error);
          setRole('guest');
        });

        fetchUserIdByEmail(user.email, user.token)
        .then(userId => setCurrentUserId(userId))
        .catch(error => {
          console.error('Error fetching user ID by email', error);
          setCurrentUserId(null);
        });
    } else {
      // If user is null or does not have an email, you may want to set the role to 'guest' or perform other actions.
      setRole('guest'); 
      setCurrentUserId(null);  // Reset user ID if no valid user
    }
  }, [user]);
    
  const signOut = () => {
    setUser(null);            // Reset user to null on sign out
    setRole('guest');         // Reset role to guest
    setIsSignedIn(false);     // Update sign in state
    setCurrentUserId(null);   // Reset user ID
  };

  return (
    <UserContext.Provider value={{
      user,
      setUser,
      role,
      setRole,
      isSignedIn,
      setIsSignedIn,
      currentUserId,
      setCurrentUserId,
      signOut      
    }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
